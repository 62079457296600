import { Import } from './importModule';

const alphabetEditorial: Import = {
  selector: ['.pts-alphabet-editorial'],
  importFn: [() => import('@components/Alphabet/AlphabetEditorial')],
};

const articleImages: Import = {
  selector: ['.pts-article-image'],
  importFn: [() => import('@components/ArticleImages/ArticleImages')],
};

const articleInfoBoxes: Import = {
  selector: ['.bo-article-text'],
  importFn: [() => import('@components/ArticleBoxes/InfoBox')],
};

const articleTagPopup: Import = {
  selector: [
    'body.portaltype-freitag-article-article #form[action$="/@@edit"]',
    '#form[action$="++add++freitag.article.article"]',
  ],
  importFn: [() => import('@components/ArticleTagPopup/ArticleTagPopup')],
};

const bannerColorChecker: Import = {
  selector: [
    'body.portaltype-freitag-article-stickybanner #form[action$="/@@edit"]',
    '#form[action*="++add++freitag.article.stickybanner"]',
  ],
  importFn: [
    async () =>
      import('@ts/components/frontend-ui/ColorChecker/BannerColorChecker'),
  ],
};

export const bentoBox: Import = {
  selector: ['.pts-bento-box-card'],
  importFn: [() => import('@components/BentoBox/BentoBox')],
};

const comments: Import = {
  selector: ['.pts-comments'],
  importFn: [() => import('@components/Comments/CommentsWrapper')],
};

const footer: Import = {
  selector: ['.pts-footer'],
  importFn: [() => import('@components/Footer/Footer')],
};

const heroColorChecker: Import = {
  selector: [
    'body.portaltype-der-freitag-herocluster #form[action$="/@@edit"]',
    '#form[action*="++add++der.freitag.herocluster"]',
  ],
  importFn: [
    async () =>
      import('@ts/components/frontend-ui/ColorChecker/HeroClusterColorChecker'),
  ],
};

const maxInputFeedback: Import = {
  selector: ['#form[action$="/@@edit"]', '#form[action*="++add++"]'],
  importFn: [
    async () =>
      import('@ts/components/frontend-ui/MaxInputFeedback/MaxInputFeedback'),
  ],
};

const newsletterSignUp: Import = {
  selector: ['.pts-newsletter-sign-up'],
  importFn: [() => import('@components/NewsletterSignUp/NewsletterSignUp')],
};

const newsletterUsp: Import = {
  selector: ['.pts-newsletter-usp'],
  importFn: [() => import('@components/NewsletterUsp/NewsletterUsp')],
};

const previewClustersOnTagPages: Import = {
  selector: ['.pts-preview-clusters-form'],
  importFn: [() => import('@components/PreviewClusters/Form')],
};

const socialShareMenu: Import = {
  selector: ['.pts-socialmedia'],
  importFn: [() => import('@components/SocialShareMenu/SocialShareMenu')],
};

const tagPageListing: Import = {
  selector: ['.pts-tag-page-listing'],
  importFn: [() => import('@components/TagPageListing/TagPageListing')],
};

export const componentModules = [
  alphabetEditorial,
  articleImages,
  articleInfoBoxes,
  articleTagPopup,
  bannerColorChecker,
  bentoBox,
  comments,
  footer,
  heroColorChecker,
  maxInputFeedback,
  newsletterSignUp,
  newsletterUsp,
  previewClustersOnTagPages,
  socialShareMenu,
  tagPageListing,
];
